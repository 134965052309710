<template>
  <b-container class="px-3">
    <b-row class="a-section-title">
      <b-col>
        <h2 class="text-center">
          <span class="w-color-black">{{ $t("Ako to") }}</span>
          <span class="w-color-primary z-fancy-underline">
            {{ $t("funguje?") }}<svg-fancy-underline-icon />
            <img src="/howItWorks.png" class="a-small-logo" alt="" />
          </span>
        </h2>
      </b-col>
    </b-row>
    <b-row align-h="center" class="how-it-works-row">
      <b-col
        v-for="(item, i) of howItWorks"
        :key="i"
        md="4"
        xl="3"
        class="py-3 py-md-0 pl-0 pr-1 d-flex d-md-block align-items-center align-items-md-start flex-column"
      >
        <div class="d-flex mb-3 mb-md-5">
          <div
            class="circle rounded-circle mr-0 mr-md-3 d-flex align-items-center justify-content-center"
          >
            <span class="w-color-primary text-center">{{ i + 1 }}</span>
          </div>
          <div
            v-if="i != howItWorks.length - 1"
            class="line m-auto d-none d-md-block"
          ></div>
        </div>
        <h3 class="d-flex flex-column">
          <span>
            {{ $t(item.title) }}
          </span>
        </h3>
        <p class="mt-4">
          {{ $t(item.description) }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center">
        <b-button
          :to="buttonLink"
          variant="primary"
          class="a-poppins-btn -medium -wide mt-3 mt-xl-5"
          >{{ buttonText }}</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import howItWorks from "/assets/json/how-it-works.json";

export default {
  components: {
    "svg-fancy-underline-icon": () =>
      import("/assets/icons/fancyUnderline.svg?inline"),
  },

  data() {
    return {
      howItWorks,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.type === "teacher";
    },
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    buttonLink() {
      if (this.isLoggedIn) {
        if (this.isAdmin) {
          return "/triedy";
        }

        return "/kurzy";
      }

      return "/registracia";
    },
    buttonText() {
      if (this.isLoggedIn) {
        if (this.isAdmin) {
          return this.$t("Vytvoriť triedu");
        }

        return this.$t("our-courses");
      }

      return this.$t("Registrujte sa zadarmo");
    },
  },
};
</script>
<style lang="sass" scoped>
.a-small-logo
	left: 100%!important

.how-it-works-row
	@media only screen and (min-width: 1200px)
		transform: translateX(5%)

.circle
	--size: 95px
	border: 8px solid rgba(var(--a-color-primary-rgb), .2)
	height: var(--size)
	flex: 1 0 var(--size)
	min-width: var(--size)
	max-width: var(--size)

	span
		font-size: 2.5rem
		font-weight: bold
		font-family: 'Boing', sans-serif

.line
	height: 1px
	width: 75%
	border: 1px solid #D8D8D8

p
	color: rgba(0, 0, 0, .6)
	max-width: 80%
</style>
